import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  subscriptionBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundImage: 'linear-gradient(to bottom, #363636, #252525)',
    padding: '1em',
    fontSize: '13px',
    margin: '2em 0',
    textAlign: 'left'
  },

  subscriptionName: {
    fontSize: '16px',
    fontWeight: 500
  },

  subscriptionFeatures: {
    paddingLeft: 0,
    listStylePosition: 'inside'
  }
}))
