import React from 'react'
import { useParams } from 'react-router-dom'
import { useCarrierToken } from '../../hooks/use-carrier-token'

import { useStyles } from '../styles'

const ErrorMap = {
  INVALID_TOKEN: {
    title: 'Invalid Session',
    subTitle: 'Your session appears to be invalid, please try again'
  },

  CONFIRM_ERROR: {
    title: 'Something went wrong',
    subTitle:
      "Sorry we couldn't transfer your billing at this time. Please try again or contact support if the problem persists"
  }
}

const Error = () => {
  const styles = useStyles()
  const { errorCode } = useParams()

  const error = (errorCode && ErrorMap[errorCode]) || {
    title: 'Houston, we have a problem',
    subTitle: "Something's gone wrong, please try again later"
  }
  const { getPartnerErrorUrl } = useCarrierToken()
  const partnerErrorUrl = getPartnerErrorUrl()

  const onStartOverClick = () => {
    if (partnerErrorUrl) {
      window.location.assign(partnerErrorUrl)
    }
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{error.title}</h1>
      <h2 className={styles.subTitle}>{error.subTitle}</h2>

      <button className={styles.button} onClick={onStartOverClick}>
        Start over
      </button>
    </div>
  )
}

export default Error
