import { createUseStyles } from 'react-jss'

const commonButtonStyles = {
  fontSize: '16px',
  marginTop: '20px',
  cursor: 'pointer',
  height: '45px',
  borderRadius: '4px',
  width: '100%',
  transition: 'all .125s ease-in',
  outline: 'none'
}

export const useStyles = createUseStyles(theme => ({
  container: {
    padding: '75px 10px 0',
    textAlign: 'center',

    [theme.media.tablet]: {
      width: '550px'
    }
  },

  title: {
    fontSize: '30px',
    lineHeight: 1.3,
    fontWeight: 500,
    marginTop: 0,

    [theme.media.tablet]: {
      fontSize: '36px',
      lineHeight: 1.08
    }
  },

  subTitle: {
    fontSize: '16px',
    lineHeight: 1.19,
    fontWeight: 'normal',

    [theme.media.tablet]: {
      fontSize: '20px',
      lineHeight: 0.95
    }
  },

  green: {
    color: theme.neonGreen
  },

  button: {
    ...commonButtonStyles,
    color: theme.black,
    border: '1px solid transparent',
    backgroundColor: theme.neonGreen,

    '&:hover': {
      backgroundColor: theme.neonGreenLight
    }
  },

  secondaryButton: {
    ...commonButtonStyles,
    color: theme.white,
    border: `1px solid ${theme.grey}`,
    backgroundColor: 'transparent',

    '&:hover': {
      borderColor: theme.neonGreen,
      color: theme.neonGreen
    }
  },

  // Button disabled for launch date. Use secondaryButton and remove declineButton style to enable decline button
  declineButton: {
    display: 'none'
  },

  form: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%'
  },

  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    textAlign: 'left',
    marginTop: '16px'
  },

  input: {
    background: 'none',
    borderRadius: '4px',
    backgroundColor: theme.darkGrey,
    border: '1px solid transparent',
    padding: '5px 20px 5px 30px',
    fontSize: '1em',
    fontWeight: 400,
    height: '45px',
    color: theme.white
  },

  label: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '16px',
    marginBottom: '6px'
  },

  fieldError: {
    marginTop: '4px',
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.red
  },

  formError: {
    color: theme.red,
    textAlign: 'center'
  },

  link: {
    color: theme.white,
    textDecoration: 'none',
    display: 'inline-block',
    cursor: 'pointer',
    paddingBottom: '2px',
    borderBottom: `1px solid ${theme.white}`,
    transition: 'all .125s ease-in',

    '&:hover': {
      color: theme.neonGreen,
      borderBottomColor: theme.neonGreen
    }
  },

  terms: {
    marginTop: '16px',
    fontSize: '14px',
    textAlign: 'center'
  },

  resetPassword: {
    fontSize: '14px',
    marginTop: '5px'
  },
  resetPasswordLink: {
    color: theme.neonGreen,
    borderBottom: `1px solid ${theme.neonGreen}`,

    '&:hover': {
      color: theme.neonGreenLight,
      borderBottomColor: theme.neonGreenLight
    }
  }
}))
