import React from 'react'
import {
  Switch, Route, Redirect, useRouteMatch
} from 'react-router-dom'

import Home from './common/home'
import Error from './common/error'
import UserInformation from './private/user-information'
import ConfirmSubscription from './private/confirm-subscription'
import StartWatching from './private/start-watching'
import ActiveDcb from './private/active-dcb'

/**
 * Responsible for rendering private routes
 * - ie : Routes that require the user to be authenticated
 */
const PrivateRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={Home} />
      <Route path={`${path}/error/:errorCode?`} component={Error} />
      <Route path={`${path}/user-info`} component={UserInformation} />
      <Route
        path={`${path}/confirm-subscription`}
        component={ConfirmSubscription}
      />
      <Route path={`${path}/start-watching`} component={StartWatching} />
      <Route path={`${path}/active-dcb`} component={ActiveDcb} />

      <Redirect to={path} />
    </Switch>
  )
}

export default PrivateRouter
