import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { path } from 'ramda'
import { SUBSCRIPTION_STATUS } from '../../../constants'
import AccountQuery from '../../../../graphql/queries/account.gql'

/**
 * Provides account & subscription information for the currently logged in user
 */
export const useAccount = () => {
  const [account, setAccount] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const { data, loading, error } = useQuery(AccountQuery)

  useEffect(() => {
    if (!data || !data.account) return

    setAccount({
      customerFirstName: data.account.name,
      customerSurname: data.account.surname,
      customerEmail: data.account.email,
      cpCustomerId: data.account.cpCustomerID
    })

    const status = path(['subscription', 'status'], data.account)
    const renewalStatus = path(['subscription', 'renewalStatus'], data.account)

    // If it's final bill we need to reactivateService
    const isFinalBill =
      status === SUBSCRIPTION_STATUS.ACTIVE &&
      renewalStatus === SUBSCRIPTION_STATUS.CANCELLED

    const hasToCancel =
      status !== SUBSCRIPTION_STATUS.NONE &&
      status !== SUBSCRIPTION_STATUS.CANCELLED

    const isDcbSubscription = path(
      ['subscription', 'dcbSubscriptionInfo'],
      data.account
    )

    if (
      data.account.subscription &&
      data.account.subscription.currentSubscription &&
      hasToCancel
    ) {
      const {
        subscription: { currentSubscription }
      } = data.account

      setSubscription({
        name: currentSubscription.name,
        sku: currentSubscription.sku,
        endsAt: currentSubscription.endsAt,
        price: currentSubscription.price,
        isFinalBill,
        isDcbSubscription
      })
    }
  }, [data])

  return {
    account,
    subscription,
    loading,
    error
  }
}
