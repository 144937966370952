import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { createContext, useContext } from 'react'

const ReduxContext = createContext(null)

/**
 * Provides access to the redux state & dispatch via the
 * ReduxContext to all of its descendants
 *
 * @param {object} state
 * @param {function} dispatch
 */
const ReduxProvider = ({ state, dispatch, children }) => (
  <ReduxContext.Provider value={{ state, dispatch }}>
    {children}
  </ReduxContext.Provider>
)

ReduxProvider.propTypes = {
  state: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dispatch: PropTypes.func.isRequired
}

/**
 * Provides an easy way to hook into redux
 */
export const useRedux = () => {
  const ctx = useContext(ReduxContext)

  if (ctx === null) {
    throw new Error('useRedux() must be used inside a <ReduxProvider />')
  }

  return ctx
}

export default connect(
  state => ({ state }),
  dispatch => ({ dispatch })
)(ReduxProvider)
