import React, { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { checkIsAuthenticated } from '../../../lib/auth'
import { logout } from '../../../actions'

const AuthContext = createContext(null)

/**
 * Provides authentication context from redux
 *
 * @param {boolean} isAuthenticated
 * @param {object} session
 * @param {function} dispatch
 */
const AuthProvider = ({
  isAuthenticated, session, dispatch, children
}) => (
  <AuthContext.Provider value={{ isAuthenticated, session, dispatch }}>
    {children}
  </AuthContext.Provider>
)

AuthProvider.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  session: PropTypes.shape({
    token: PropTypes.string,
    decodedJwt: {
      first_name: PropTypes.string,
      email: PropTypes.string
    }
  }),
  dispatch: PropTypes.func
}

/**
 * Consumes the authentication context to provide user information
 * and other authentication functions
 */
export const useAuth = () => {
  const ctx = useContext(AuthContext)

  if (ctx === null) throw new Error('useAuth() can only be used inside an <AuthProvider />')

  const handleLogout = () => {
    ctx.dispatch(logout())
  }

  let user = null
  let token = null

  if (ctx.session) {
    token = ctx.session.token
    user = {
      firstName: ctx.session.decodedJwt.first_name,
      email: ctx.session.decodedJwt.email
    }
  }

  return {
    user,
    token,
    logout: handleLogout,
    isAuthenticated: ctx.isAuthenticated
  }
}

export default connect(
  state => ({
    isAuthenticated: checkIsAuthenticated(state),
    session: state.session
  }),
  dispatch => ({ dispatch })
)(AuthProvider)
