import React from 'react'
import {
  Switch, Route, Redirect, useRouteMatch
} from 'react-router-dom'

import Home from './common/home'
import Error from './common/error'
import Login from './public/login'
import CreateAccount from './public/create-account'

/**
 * Responsible for rendering public routes
 */
const PublicRouter = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={Home} />
      <Route path={`${path}/error/:errorCode?`} component={Error} />
      <Route path={`${path}/login`} component={Login} />
      <Route path={`${path}/create-account`} component={CreateAccount} />

      <Redirect to={path} />
    </Switch>
  )
}

export default PublicRouter
