import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  container: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '2em 0 0 0',
    minHeight: '5em',
    alignItems: 'center',

    [theme.media.tablet]: {
      justifyContent: 'flex-start',
      padding: '2em 2em'
    }
  },

  logo: {
    height: '40px'
  }
}))
