import { useState } from 'react'

import { useRedux } from './use-redux'
import {
  endLoadingOverlay,
  enableLoadingOverlay,
  disableLoadingOverlay
} from '../../../actions'

/**
 * Provides functions to control the loading overlay
 *
 * - Hooks into the existing loading overlay via the useRedux hook
 */
export const useLoader = () => {
  const [key] = useState(new Date().getTime())
  const { dispatch } = useRedux()

  const startLoader = operation => {
    dispatch(enableLoadingOverlay(key, operation))
  }

  const endLoader = operation => {
    dispatch(endLoadingOverlay(key, operation))
  }

  const hideLoader = operation => {
    dispatch(endLoadingOverlay(key, operation))
    dispatch(disableLoadingOverlay(operation))
  }

  return { startLoader, endLoader, hideLoader }
}
