import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAccount } from '../../hooks/use-account'
import { useLoader } from '../../hooks/use-loader'
import { useCarrierToken } from '../../hooks/use-carrier-token'
import { useStyles } from '../styles'
import { LINKS } from '../../constants'

const ActiveDcb = () => {
  const styles = useStyles()
  const { loading, error, account } = useAccount()
  const { startLoader, hideLoader } = useLoader()
  const { push } = useHistory()

  const {
    getPartnerName,
    getPartnerErrorUrl,
    getPartnerHelpUrl
  } = useCarrierToken()
  const partnerName = getPartnerName()
  const partnerErrorUrl = getPartnerErrorUrl() || '#'
  const partnerHelp = getPartnerHelpUrl() || '#'

  useEffect(() => {
    if (loading) {
      startLoader()
      return
    }

    if (error) {
      push(`${LINKS.ERROR}/ACCOUNT_ISSUE`)
    }

    hideLoader()
  }, [loading, error, account])

  return loading || !account ? (
    <div />
  ) : (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {'Sorry looks like you already have '}
        {partnerName}
        {' billing set up for: '}
        <span className={styles.green}>{account.customerEmail}</span>
      </h1>
      <h2 className={styles.subTitle}>
        {'To sign up with a different email address go back to '}
        <a href={partnerErrorUrl} className={styles.link}>
          {partnerName}
        </a>
        {' and click the Activate button. Otherwise checkout the '}
        {partnerName}
        {' help page for more information. '}
        <a href={partnerHelp} className={styles.link}>
          {'Click here to go to '}
          {partnerName}
          {' help'}
        </a>
      </h2>
    </div>
  )
}

export default ActiveDcb
