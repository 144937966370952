import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose, withProps } from 'recompose'

export default (MUTATION, name, options) => compose(
  graphql(MUTATION, {
    options,
    name
  }),
  connect(
    () => ({}),
    dispatch => ({
      dispatch
    })
  ),
  withProps(({ dispatch, ...props }) => ({
    /**
       * @param  {string} operation - Used in loading reducer
       *            to set loading, success and error messages
       * @param  {Object} ...args - (apollo) mutate arguments
       */
    [name]: (operation, ...args) => {
      return props[name](...args)
        .then(res => {
          return res
        })
        .catch(error => {
          throw error
        })
    }
  }))
)
