import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { confirmOrder } from '../../lib/confirm-order'
import SubscriptionBox from '../../components/subscription-box'
import { useCarrierToken } from '../../hooks/use-carrier-token'
import { STATIC_SCREENS } from '../../../../constants'
import { useAccount } from '../../hooks/use-account'
import { cancelOrder } from '../../lib/cancel-order'
import { useLoader } from '../../hooks/use-loader'
import { useAuth } from '../../hooks/use-auth'
import { LINKS } from '../../constants'
import { useStyles } from '../styles'

const ConfirmSubscription = () => {
  const { push } = useHistory()
  const styles = useStyles()
  const { startLoader, hideLoader } = useLoader()
  const {
    getCarrierToken,
    getPartnerName,
    getPartnerErrorUrl
  } = useCarrierToken()
  const carrierToken = getCarrierToken()
  const partnerErrorUrl = getPartnerErrorUrl()
  const partnerName = getPartnerName()
  const { token } = useAuth()

  const {
    loading,
    error,
    account,
    subscription
  } = useAccount()

  useEffect(() => {
    if (loading) {
      startLoader()
      return
    }

    if (error) {
      push(`${LINKS.ERROR}/ACCOUNT_ISSUE`)
    }

    if (subscription && subscription.isDcbSubscription) {
      push(`${LINKS.ACTIVE_DCB}`)
    }

    hideLoader()
  }, [loading, error, subscription])

  const confirmSubscription = () => {
    const confirm = async () => {
      try {
        startLoader()
        await confirmOrder(carrierToken, token, account, subscription)

        hideLoader()
        push(LINKS.START_WATCHING)
      } catch (ex) {
        hideLoader()
        push(`${LINKS.ERROR}/CONFIRM_ERROR`)
      }
    }

    confirm()
  }

  const declineOffer = () => {
    const decline = async () => {
      try {
        startLoader()

        await cancelOrder(carrierToken, account)

        hideLoader()
        if (partnerErrorUrl) {
          push(partnerErrorUrl)
        } else {
          throw new Error(`Couldn't redirect back to your provider`)
        }
      } catch (ex) {
        hideLoader()
        push(`${LINKS.ERROR}/${ex.message}`)
      }
    }

    decline()
  }

  return loading ? (
    <div />
  ) : (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {'Transfer your billing for Neon to '}
        {partnerName}
      </h1>
      {subscription && (
        <h2 className={styles.subTitle}>
          {'Your current '}
          <span className={styles.green}>{subscription.name}</span>
          {' subscription for '}
          <span className={styles.green}>{`$${subscription.price}`}</span>
          {' due to renew on '}
          <span className={styles.green}>{subscription.endsAt}</span>
          {' will be cancelled once you click confirm'}
        </h2>
      )}

      <SubscriptionBox />

      <button className={styles.button} onClick={confirmSubscription}>
        {'Confirm, I want to pay for Neon through '}
        {partnerName}
      </button>
      <button className={styles.declineButton} onClick={declineOffer}>
        {subscription
          ? "No thanks, I'll keep my current subscription"
          : 'No Thanks'}
      </button>

      <div className={styles.terms}>
        <span>
          <a
            className={styles.link}
            href={STATIC_SCREENS.TERMS}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
          {' & '}
          <a
            className={styles.link}
            href={STATIC_SCREENS.PRIVACY}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </span>
      </div>
    </div>
  )
}

export default ConfirmSubscription
