/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'

import uncheckedIcon from '../../../../../images/neon/checkbox-unchecked.svg'
import checkedIcon from '../../../../../images/neon/checkbox-checked.svg'
import { useStyles } from './checkbox.styles'

function Checkbox({
  isChecked,
  label,
  onChange,
  checked,
  setChecked
}) {
  const styles = useStyles()
  const icon = checked ? checkedIcon : uncheckedIcon

  const onCLick = evt => {
    evt.stopPropagation()
    evt.preventDefault()
    onChange(evt, !checked)
    setChecked(prevChecked => !prevChecked)
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div data-lbx-e2e="checkbox" onClick={onCLick} className={styles.checkboxWrapper}>
      <img // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={styles.img}
        onKeyPress={onCLick}
        tabIndex={0}
        src={icon}
        alt={`${isChecked}`}
      />

      {label && (
        <label className={styles.label}>
          {label}
        </label>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.node,
  onChange: PropTypes.func,
  setChecked: PropTypes.func
}

Checkbox.defaultProps = {
  label: '',
  onChange: (evt, value, checked) => {},
  setChecked: checked => {}
}

export default compose(
  withState('checked', 'setChecked', props => props.isChecked)
)(Checkbox)
