import React from 'react'
import { useStyles } from './subscription-box.styles'
import { useCarrierToken } from '../../hooks/use-carrier-token'
/**
 * Renders a box containing subscription information
 *
 * TODO: Get carrier subscription details from API
 */
const SubscriptionBox = () => {
  const styles = useStyles()
  const { getPartnerName } = useCarrierToken()
  const partnerName = getPartnerName()

  const carrierSubscription = {
    features: [
      'Hit shows and blockbuster movies',
      'Binge-watch with no ads',
      'Download & Go selected content',
      'Watch on two screens at once'
    ]
  }

  return (
    <div className={styles.subscriptionBox}>
      <p className={styles.subscriptionName}>{partnerName}</p>

      <ul className={styles.subscriptionFeatures}>
        {carrierSubscription.features.map(feature => (
          <li key={feature}>{feature}</li>
        ))}
      </ul>
    </div>
  )
}

export default SubscriptionBox
