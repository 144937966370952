import { Formik } from 'formik'
import { withRouter } from 'react-router-dom'
import { path, pathOr, prop } from 'ramda'
import {
  compose,
  setDisplayName,
  withHandlers,
  withProps,
  withState
} from 'recompose'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import jwtDecode from 'jwt-decode'

import { FORM_MESSAGES, FORM_VALUES } from '../../../constants'
import { checkIsAuthenticated } from '../../../lib/auth'
import { findConfigValue } from '../../../lib/config'
import { getGQLErrorMsg } from '../../../lib/apollo'
import { getSearchProperty } from '../../../lib/query-string'
import { loginGa } from '../../../lib/analytics/ga'
import Yup from '../../../lib/yup'
import {
  enableLoadingOverlay,
  endLoadingOverlay,
  login
} from '../../../actions'
import withConfigCacheOnly from '../../../hoc/with-config'

import ACCOUNT_QUERY from '../../../../graphql/queries/account.gql'
import LOGIN_QUERY from '../../../../graphql/queries/login.gql'

import { customDimensions } from '../../../lib/analytics/custom-dimensions'
import withDeviceFingerprint from '../../../hoc/with-device-fingerprint'

const mapStateToProps = state => {
  return {
    isAuthenticated: checkIsAuthenticated(state),
    accounts: state.accounts
  }
}

export const enhance = compose(
  setDisplayName('LoginContainer'),
  withRouter,
  withApollo,
  withConfigCacheOnly,
  withState('rememberMe', 'setRememberMe', true),
  withHandlers({
    onRememberMeChange: ({ setRememberMe }) => (event, isChecked) => {
      setRememberMe(isChecked)
    }
  }),
  withDeviceFingerprint,
  withProps(({ appConfig }) => ({
    email: getSearchProperty('email'),
    loginBackgroundUri: findConfigValue('URL_LOGIN_BACKGROUND')(appConfig)
  })),
  connect(mapStateToProps, dispatch => ({
    dispatch
  })),
  Formik({
    // Define our form's validation schema with Yup. It's like Joi, but for
    // the browser.
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(FORM_MESSAGES.email.valid)
        .required(FORM_MESSAGES.required),
      password: Yup.string()
        .required(FORM_MESSAGES.required)
        .min(FORM_VALUES.password.min, FORM_MESSAGES.password.min)
    }),

    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: ({ email }) => {
      return {
        email,
        password: ''
      }
    },

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, { props, setError, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setError('')
      const key = new Date().getTime()
      props.dispatch(enableLoadingOverlay(key, 'login'))
      props.client
        .query({
          query: LOGIN_QUERY,
          variables: {
            email: values.email,
            password: values.password
          }
        })
        .then(result => {
          // Since we get the full account here,
          // might as well put it in the store for later!
          props.client.writeQuery({
            query: ACCOUNT_QUERY,
            data: {
              account: path(['data', 'login'], result)
            }
          })
          return result
        })
        .then(result => {
          setSubmitting(false)

          const loginData = pathOr({}, ['data', 'login'], result)
          const session = path(['data', 'login', 'session'], result)
          const jwtDecoded = jwtDecode(prop('token', session))
          const accountId = prop('accountId', jwtDecoded)
          const sessionId = prop('sessionId', jwtDecoded)

          const { dispatch, rememberMe } = props
          dispatch(login(loginData, rememberMe))

          loginGa({
            action: 'Manual login',
            label: accountId,
            [customDimensions.SessionId]: sessionId,
            [customDimensions.UserId]: accountId
          })
        })
        .then(() => {
          props.dispatch(endLoadingOverlay(key, 'login', { timeout: 2000 }))
        })
        .catch(error => {
          setSubmitting(false)
          setError(getGQLErrorMsg(error))
          props.dispatch(endLoadingOverlay(key, 'login', { error }))
        })
    }
  })
)
