import React from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../../hooks/use-auth'
import { useCarrierToken } from '../../hooks/use-carrier-token'
import { LINKS } from '../../constants'
import { useStyles } from '../styles'

const Home = () => {
  const { push } = useHistory()
  const { isAuthenticated } = useAuth()
  const styles = useStyles()
  const { getPartnerName } = useCarrierToken()
  const partnerName = getPartnerName()

  if (isAuthenticated) {
    push(LINKS.USER_INFO)
    return null
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {'Transfer your billing for Neon to '}
        {partnerName}
      </h1>
      <h2 className={styles.subTitle}>
        Log in or create a new account to continue
      </h2>

      <button className={styles.button} onClick={() => push(LINKS.LOGIN)}>
        Log in
      </button>

      <button
        className={styles.secondaryButton}
        onClick={() => push(LINKS.CREATE_ACCOUNT)}
      >
        Create a new account
      </button>
    </div>
  )
}

export default Home
