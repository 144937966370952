import React from 'react'
import { createUseStyles } from 'react-jss'
import { Helmet } from 'react-helmet'

import { useAuth } from './hooks/use-auth'
import { useCarrierToken } from './hooks/use-carrier-token'
import Header from './components/header'
import PublicScreenRouter from './screens/public-screen-router'
import PrivateScreenRouter from './screens/private-screen-router'

const useStyles = createUseStyles(theme => ({
  container: {
    height: '100vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0 2em',
    fontSize: '13px',
    color: 'white',

    [theme.media.tablet]: {
      fontSize: '16px',
      justifyContent: 'flex-start'
    }
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    alignSelf: 'center',

    [theme.media.tablet]: {
      width: '50%'
    }
  }
}))

/**
 * Renders the base layout for direct carrier billing
 */
const Layout = () => {
  const { isAuthenticated } = useAuth()
  const styles = useStyles()
  const { getPartnerName } = useCarrierToken()
  const partnerName = getPartnerName()

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{`Transfer your billing for Neon to ${partnerName}`}</title>
      </Helmet>

      <Header />

      <div className={styles.content}>
        {isAuthenticated ? <PrivateScreenRouter /> : <PublicScreenRouter />}
      </div>
    </div>
  )
}

export default Layout
