import React from 'react'
import { ThemeProvider } from 'react-jss'

import AuthProvider from './hooks/use-auth'
import CarrierTokenProvider from './hooks/use-carrier-token'
import ReduxProvider from './hooks/use-redux'
import Layout from './layout'
import theme from './theme'

/**
 * Entry-point for the direct carrier billing module
 */
const DirectCarrierBillingModule = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CarrierTokenProvider>
          <ReduxProvider>
            <Layout />
          </ReduxProvider>
        </CarrierTokenProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default DirectCarrierBillingModule
