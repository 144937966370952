import jwtDecode from 'jwt-decode'
import { prop } from 'ramda'
import { DCB_API_ENDPOINT, DCB_V2_API_ENDPOINT } from '../../../constants'

// LBX-7383 to use both V1 and V2 for a while
// Once all partners move to V2, clean V1 DCB API ENDPOINT
export const getDcbApiEndpoint = (carrierToken) => {
  const decodedToken = jwtDecode(carrierToken)

  if (prop('partnerId', decodedToken)) {
    return DCB_V2_API_ENDPOINT
  }

  return DCB_API_ENDPOINT
}
