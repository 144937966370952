import { useEffect } from 'react'
import { useNode } from './use-node'

/**
 * Focuses the referenced element on mount
 */
export const useFocusOnMount = () => {
  const [ref, node] = useNode()

  useEffect(() => {
    if (node === null) return
    node.focus()
  }, [node])

  return ref
}
