import { getDcbApiEndpoint } from './get-dcb-api-endpoint'

/**
 * Calls into the order cancel endpoint to let the carrier know that the
 * customer has declined to link their account.
 *
 * @param {string} carrierToken The session token provided by the carrier
 * @param {object} account Basic customer details (firstName, surname, email)
 */
export const cancelOrder = async (carrierToken, account) => {
  const dcbApiEndpoint = getDcbApiEndpoint(carrierToken)

  const response = await fetch(`${dcbApiEndpoint}/order/cancel`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${carrierToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(account)
  })

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data.errorCode)
  }
}
