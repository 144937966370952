import React from 'react'
import { Link } from 'react-router-dom'

import { useStyles } from './header.styles'
import NeonLogo from '../../../../../images/neon/neon-logo-hoz-wht-rgb.svg'

/**
 * Renders a simple neon logo
 */
const Header = () => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <Link to="/link-account">
        <img src={NeonLogo} alt="Neon Logo" className={styles.logo} />
      </Link>
    </div>
  )
}

export default Header
