import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  checkboxWrapper: {
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '20px',

    '&:focus': {
      outline: 'none'
    }
  },

  img: {
    border: `1px solid ${theme.grey}`,
    borderRadius: '2px'
  },

  label: {
    fontSize: '14px',
    marginLeft: '5px'
  }
}))
