import { getDcbApiEndpoint } from './get-dcb-api-endpoint'

/**
 * Calls into a session validation API to make sure the user is able to
 * link their account.
 *
 * @param {string} carrierToken The session token provided by the carrier
 */
export const validateSession = async (carrierToken) => {
  const dcbApiEndpoint = getDcbApiEndpoint(carrierToken)

  const response = await fetch(`${dcbApiEndpoint}/session/validate`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${carrierToken}`
    }
  })

  const json = await response.json()

  if (!response.ok) {
    throw Error(json)
  }
}
