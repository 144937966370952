const theme = {
  neonGreen: '#cafd34',
  neonGreenLight: '#e6ff9d',
  black: '#333',
  white: '#fff',
  grey: '#979797',
  darkGrey: '#303030',
  red: '#f95c4f',
  media: {
    tablet: '@media(min-width:768px)',
    desktop: '@media(min-width:1024px)'
  }
}

export default theme
