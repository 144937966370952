import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

import { useFocusOnMount } from '../../hooks/use-focus-on-mount'
import { enhance } from '../../containers/create-account'
import { STATIC_SCREENS } from '../../../../constants'
import Checkbox from '../../components/checkbox'
import { LINKS } from '../../constants'
import { useStyles } from '../styles'

const CreateAccount = ({
  values,
  errors,
  touched,
  handleSubmit,
  handleChange,
  handleBlur,
  onOptInChange,
  onTermsAndConditionsChange
}) => {
  const { push } = useHistory()
  const styles = useStyles()
  const firstElementRef = useFocusOnMount()

  // AM - This is because new relic is capturing links with target=_blank for some reason...
  const openLink = path => e => {
    e.preventDefault()
    e.stopPropagation()

    window.open(path)
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Create account</h1>

      <div className={classNames(styles.formGroup, styles.formError)}>
        {errors && errors.API}
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="firstName">
            First Name
          </label>
          <input
            className={styles.input}
            type="text"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={firstElementRef}
          />
          <div className={styles.fieldError}>
            {touched.firstName && errors.firstName}
          </div>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="lastName">
            Last Name
          </label>
          <input
            className={styles.input}
            type="text"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className={styles.fieldError}>
            {touched.lastName && errors.lastName}
          </div>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="email">
            Email
          </label>
          <input
            className={styles.input}
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className={styles.fieldError}>
            {touched.email && errors.email}
          </div>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="emailConfirmation">
            Confirm Email
          </label>
          <input
            className={styles.input}
            type="email"
            name="emailConfirmation"
            value={values.emailConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className={styles.fieldError}>
            {touched.emailConfirmation && errors.emailConfirmation}
          </div>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="password">
            Password
          </label>
          <input
            className={styles.input}
            type="password"
            name="password"
            values={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className={styles.fieldError}>
            {touched.password && errors.password}
          </div>
        </div>

        <div className={styles.formGroup}>
          <Checkbox
            isChecked
            label="I am happy to receive emails promoting Neon services"
            onChange={onOptInChange}
          />

          <Checkbox
            isChecked={values.termsAndConditions}
            onChange={onTermsAndConditionsChange}
            label={(
              <span>
                I agree to the Neon
                {' '}
                <a
                  className={styles.link}
                  href={STATIC_SCREENS.TERMS}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={openLink(STATIC_SCREENS.TERMS)}
                >
                  Terms of Use
                </a>
                {' & '}
                <a
                  className={styles.link}
                  href={STATIC_SCREENS.PRIVACY}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={openLink(STATIC_SCREENS.PRIVACY)}
                >
                  Privacy Policy
                </a>
              </span>
            )}
          />

          <div className={styles.fieldError}>
            {touched.termsAndConditions && errors.termsAndConditions}
          </div>
        </div>

        <button className={styles.button} type="submit">
          Create my account
        </button>

        <button
          className={styles.secondaryButton}
          onClick={() => push(LINKS.HOME)}
        >
          Back
        </button>
      </form>
    </div>
  )
}

CreateAccount.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onOptInChange: PropTypes.func.isRequired,
  onTermsAndConditionsChange: PropTypes.func.isRequired
}

export default enhance(CreateAccount)
