const BASE_PATH = '/link-account'

export const LINKS = {
  HOME: BASE_PATH,
  LOGIN: `${BASE_PATH}/login`,
  CREATE_ACCOUNT: `${BASE_PATH}/create-account`,
  USER_INFO: `${BASE_PATH}/user-info`,
  CONFIRM: `${BASE_PATH}/confirm-subscription`,
  START_WATCHING: `${BASE_PATH}/start-watching`,
  ERROR: `${BASE_PATH}/error`,
  ACTIVE_DCB: `${BASE_PATH}/active-dcb`
}
