import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

import { useFocusOnMount } from '../../hooks/use-focus-on-mount'
import { enhance } from '../../containers/login'
import Checkbox from '../../components/checkbox'
import { LINKS } from '../../constants'
import { useStyles } from '../styles'
import { MODALS } from '../../../../constants'

const Login = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  error,
  touched,
  rememberMe,
  onRememberMeChange
}) => {
  const { push } = useHistory()
  const styles = useStyles()
  const firstElementRef = useFocusOnMount()
  const resetPasswordUrl = new URL(window.location.origin)
  resetPasswordUrl.searchParams.append('email', encodeURI(values.email || ''))
  resetPasswordUrl.searchParams.append(
    'modal',
    MODALS.qsParams.passwordForgotten
  )

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Log in</h1>

      <div className={classNames(styles.formGroup, styles.formError)}>
        {error}
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="email">
            Email
          </label>

          <input
            className={styles.input}
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={firstElementRef}
          />

          <div className={styles.fieldError}>
            {touched.email && errors.email}
          </div>
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="password">
            Password
          </label>

          <input
            className={styles.input}
            type="password"
            name="password"
            value={values.password}
            min={6}
            max={21}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <div className={styles.fieldError}>
            {touched.password && errors.password}
          </div>
        </div>

        <div className={styles.formGroup}>
          <Checkbox
            label="Remember me"
            isChecked={rememberMe}
            onChange={onRememberMeChange}
          />
          <div className={styles.resetPassword}>
            Forgot your password? Reset your password via the Neon website
            {' '}
            <a
              href={resetPasswordUrl}
              target="_blank"
              className={styles.resetPasswordLink}
              rel="noopener noreferrer"
            >
              here
            </a>
            <div className={styles.resetPassword}>
              Don’t forget to come back to this screen once you’re done to
              continue setting up your account.
            </div>
          </div>
        </div>

        <button className={styles.button} onClick={handleSubmit} type="submit">
          Log in
        </button>

        <button
          className={styles.secondaryButton}
          onClick={() => push(LINKS.HOME)}
        >
          Back
        </button>
      </form>
    </div>
  )
}

Login.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  rememberMe: PropTypes.bool.isRequired,
  onRememberMeChange: PropTypes.func.isRequired
}

export default enhance(Login)
