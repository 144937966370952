import { getDcbApiEndpoint } from './get-dcb-api-endpoint'

/**
 * Calls into the DCB API to confirm order with a voucher for the carrier customer
 *
 * @param {string} carrierToken Token issued by the DCB API
 * @param {string} neonToken Token issued by the Neon API
 * @param {object} account Basic account details of the user (firstName, lastName, email)
 * @param {object} subscription Current subscription information or null if no subscription exists
 */
export const confirmOrder = async (
  carrierToken,
  neonToken,
  account,
  subscription
) => {
  const dcbApiEndpoint = getDcbApiEndpoint(carrierToken)

  const response = await fetch(`${dcbApiEndpoint}/order/confirm`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${carrierToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      token: neonToken,
      removeSubscriptionSkus: subscription ? [subscription.sku] : undefined,
      reactivateSubscriptionSkus:
        subscription && subscription.isFinalBill
          ? [subscription.sku]
          : undefined,
      ...account
    })
  })

  const data = await response.json()

  if (!response.ok) {
    throw new Error(data.errorCode)
  }
}
