import { useState, useCallback } from 'react'

/**
 * Allows persistent access to dom nodes based on react refs
 */
export const useNode = () => {
  const [node, setNode] = useState(null)

  const ref = useCallback(domNode => {
    if (domNode === null) return
    setNode(domNode)
  })

  return [ref, node]
}
