import React from 'react'
import { useHistory } from 'react-router-dom'

import { useCarrierToken } from '../../hooks/use-carrier-token'
import { useAuth } from '../../hooks/use-auth'
import { LINKS } from '../../constants'
import { useStyles } from '../styles'

const UserInformation = () => {
  const { push } = useHistory()
  const { user, logout } = useAuth()
  const styles = useStyles()
  const { getPartnerName } = useCarrierToken()
  const partnerName = getPartnerName()

  const logoutAndRouteTo = path => () => {
    logout()
    push(path)
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {'Transfer your billing for Neon to '}
        {partnerName}
      </h1>
      <h2 className={styles.subTitle}>
        You&apos;re currently logged in as
        {' '}
        <span className={styles.green}>{user.email}</span>
      </h2>

      <button className={styles.button} onClick={() => push(LINKS.CONFIRM)}>
        Continue as
        {' '}
        {user.firstName}
      </button>

      <button
        className={styles.secondaryButton}
        onClick={logoutAndRouteTo(LINKS.LOGIN)}
      >
        Log in with another account
      </button>

      <button
        className={styles.secondaryButton}
        onClick={logoutAndRouteTo(LINKS.CREATE_ACCOUNT)}
      >
        Create a new account
      </button>
    </div>
  )
}

export default UserInformation
