import {
  compose,
  withHandlers,
  fromRenderProps
} from 'recompose'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { withRouter } from 'react-router-dom'
import { path, prop } from 'ramda'
import jwtDecode from 'jwt-decode'

import Yup from '../../../lib/yup'
import { getGQLErrorMsg } from '../../../lib/apollo'

import {
  login,
  enableLoadingOverlay,
  endLoadingOverlay
} from '../../../actions'

import { FORM_VALUES, FORM_MESSAGES } from '../../../constants'
import withMutation from './with-mutation'
import { checkIsAuthenticated } from '../../../lib/auth'

import ACCOUNT_MUTATION from '../../../../graphql/mutations/account.gql'
import withDeviceFingerprint from '../../../hoc/with-device-fingerprint'

import { CarrierTokenContext } from '../hooks/use-carrier-token'
import { confirmOrder } from '../lib/confirm-order'
import { LINKS } from '../constants'

const mapStateToProps = state => {
  const isAuthenticated = checkIsAuthenticated(state)
  return {
    isAuthenticated // used to skip account query
  }
}

export const enhance = compose(
  withRouter,
  withDeviceFingerprint,
  withMutation(ACCOUNT_MUTATION, 'createAccount'),
  connect(mapStateToProps, dispatch => ({
    dispatch
  })),
  fromRenderProps(CarrierTokenContext.Consumer, ctx => ({ getCarrierToken: ctx.getCarrierToken })),
  Formik({
    validateOnChange: true,

    // Define the form's validation schema with Yup.
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(FORM_MESSAGES.required),
      lastName: Yup.string().required(FORM_MESSAGES.required),
      email: Yup.string()
        .email(FORM_MESSAGES.email.valid)
        .required(FORM_MESSAGES.required),
      emailConfirmation: Yup.string()
        .email(FORM_MESSAGES.email.valid)
        .required(FORM_MESSAGES.required)
        .sameAs(Yup.ref('email'), FORM_MESSAGES.email.match),
      password: Yup.string()
        .required(FORM_MESSAGES.required)
        .min(FORM_VALUES.password.min, FORM_MESSAGES.password.min),
      termsAndConditions: Yup.bool().oneOf(
        [true],
        'You must agree to the terms of use and privacy policy'
      )
    }),

    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: ({ getCarrierToken }) => {
      const carrierToken = getCarrierToken()
      const decodedToken = jwtDecode(carrierToken)
      const email = prop('email', decodedToken)

      return {
        firstName: '',
        lastName: '',
        email,
        emailConfirmation: email,
        password: '',
        optInNewsletter: true,
        termsAndConditions: false
      }
    },

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, { props, setErrors, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      const {
        history, dispatch, createAccount, getCarrierToken
      } = props

      const key = new Date().getTime()
      const carrierToken = getCarrierToken()
      dispatch(enableLoadingOverlay(key, 'signup'))

      createAccount('signup', {
        variables: {
          input: {
            name: values.firstName,
            surname: values.lastName,
            email: values.email,
            password: values.password,
            optIns: [
              {
                id: 'RECEIVE_UPDATES',
                subscribed: values.optInNewsletter
              }
            ]
          }
        }
      })
        .then(response => {
          setSubmitting(false)

          dispatch(login(path(['data', 'account'], response), true))

          const account = {
            customerFirstName: path(['data', 'account', 'name'], response),
            customerSurname: path(['data', 'account', 'surname'], response),
            customerEmail: path(['data', 'account', 'email'], response),
            cpCustomerId: path(['data', 'account', 'cpCustomerID'], response)
          }

          const sessionToken = path(
            ['data', 'account', 'session', 'token'],
            response
          )

          let subscription = null
          if (
            response.data.subscription &&
            response.data.subscription.currentSubscription
          ) {
            const { currentSubscription } = response.data.subscription

            subscription = {
              name: currentSubscription.name,
              sku: currentSubscription.sku,
              endsAt: currentSubscription.endsAt
            }
          }

          return confirmOrder(
            carrierToken,
            sessionToken,
            account,
            subscription
          )
            .then(() => {
              dispatch(endLoadingOverlay(key, 'signup', { timeout: 2000 }))
              history.push(LINKS.START_WATCHING)
            })
            .catch(error => {
              dispatch(
                endLoadingOverlay(key, 'signup', { timeout: 2000, error: true })
              )
              setSubmitting(false)
              history.push(`${LINKS.ERROR}/${error.message}`)
            })
        })
        .catch(error => {
          dispatch(
            endLoadingOverlay(key, 'signup', { timeout: 2000, error: true })
          )
          setSubmitting(false)
          setErrors({ API: getGQLErrorMsg(error) })
          throw error
        })
    }
  }),
  withHandlers({
    onOptInChange: props => (event, isChecked) => {
      // send the mutation Account query to update the account optin
      props.setFieldValue('optInNewsletter', isChecked)
    },

    onTermsAndConditionsChange: props => (event, isChecked) => {
      props.setFieldValue('termsAndConditions', isChecked)
    }
  })
)
