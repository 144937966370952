import React from 'react'
import { useHistory } from 'react-router-dom'

import { useCarrierToken } from '../../hooks/use-carrier-token'
import { useStyles } from '../styles'

const StartWatching = () => {
  const { push } = useHistory()
  const styles = useStyles()
  const { getPartnerName } = useCarrierToken()
  const partnerName = getPartnerName()

  const handleStartWatching = () => {
    sessionStorage.removeItem('DCB_TOKEN')
    push('/')
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>You&apos;re good to go</h1>

      <h2 className={styles.subTitle}>
        {'You have successfully transferred your billing for Neon to '}
        {partnerName}
        {'. You can manage your subscription by visiting your '}
        {partnerName}
        {' My Account.'}
      </h2>

      <button className={styles.button} onClick={handleStartWatching}>
        Start watching
      </button>
    </div>
  )
}

export default StartWatching
