import React, {
  createContext,
  useContext,
  useState,
  useEffect
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { prop } from 'ramda'
import jwtDecode from 'jwt-decode'

import { validateSession } from '../lib/validate-session'
import { LINKS } from '../constants'

export const CarrierTokenContext = createContext(null)

const defaultErrorUrl = `https://www.spark.co.nz/secure/myspark/getmore?activeTab=Neon`
const defaultHelpUrl = `https://www.spark.co.nz/help/get-more/set-up-and-manage-neon/`
const defaultPartnerName = 'Spark'
/**
 * Responsible for sensing, validating and providing the carrier
 * session token to the rest of the application.
 *
 * - Senses the token on initial load through query params
 * - Handles cases where the token doesn't exist or is invalid
 * - Provides the token through the carrier token context to all descendants
 * - Trusts token from session storage
 */
const CarrierTokenProvider = ({ children }) => {
  const { replace } = useHistory()
  const location = useLocation()
  const { pathname } = location

  const getCarrierToken = () => {
    return sessionStorage.getItem('DCB_TOKEN')
  }

  const getInvalidCarrierToken = () => {
    return sessionStorage.getItem('DCB_INVALID_TOKEN')
  }

  const getDecodedToken = () => {
    const token = getCarrierToken()
    const invalidToken = getInvalidCarrierToken()
    if (token) {
      return jwtDecode(token)
    }
    if (invalidToken) {
      return jwtDecode(invalidToken)
    }

    return null
  }
  const getPartnerErrorUrl = () => {
    const decodedToken = getDecodedToken()
    return prop('partnerErrorUrl', decodedToken) || defaultErrorUrl
  }
  const getPartnerHelpUrl = () => {
    const decodedToken = getDecodedToken()
    return prop('partnerHelp', decodedToken) || defaultHelpUrl
  }
  const getPartnerName = () => {
    const decodedToken = getDecodedToken()
    return prop('partnerName', decodedToken) || defaultPartnerName
  }
  const [isLoading, setIsLoading] = useState(true)

  const handleValidToken = validToken => {
    sessionStorage.setItem('DCB_TOKEN', validToken)
    sessionStorage.removeItem('DCB_INVALID_TOKEN')
    setIsLoading(false)
  }

  const handleInvalidToken = invalidToken => {
    if (invalidToken) {
      sessionStorage.setItem('DCB_INVALID_TOKEN', invalidToken)
    } else {
      sessionStorage.removeItem('DCB_INVALID_TOKEN')
    }
    replace(`${LINKS.ERROR}/INVALID_TOKEN`)
    setIsLoading(false)
  }

  useEffect(() => {
    if (pathname === `${LINKS.ERROR}/INVALID_TOKEN`) {
      setIsLoading(false)
      return
    }

    const validateToken = async token => {
      try {
        await validateSession(token)

        handleValidToken(token)
      } catch (error) {
        handleInvalidToken(token)
      }
    }

    // Always validate token if it's present in url
    const query = queryString.parse(location.search)
    if (query.token) {
      validateToken(query.token)
      return
    }

    // If token is not present in session storage return error
    const token = sessionStorage.getItem('DCB_TOKEN')
    if (!token) {
      handleInvalidToken()
      return
    }

    setIsLoading(false)
  }, [pathname])

  return (
    <CarrierTokenContext.Provider
      value={{
        getCarrierToken,
        getPartnerErrorUrl,
        getPartnerHelpUrl,
        getPartnerName
      }}
    >
      {isLoading ? <div>Loading...</div> : children}
    </CarrierTokenContext.Provider>
  )
}

/**
 * Provides easy access to the carrier token from any
 * component that's a descendant of <CarrierTokenProvider />
 */
export const useCarrierToken = () => {
  const ctx = useContext(CarrierTokenContext)

  if (ctx === null) {
    throw new Error(
      'useCarrierToken() can only be used inside a <CarrierTokenProvider />'
    )
  }

  return ctx
}

export default CarrierTokenProvider
